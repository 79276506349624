@use '../../style/_colors.scss';

.header-container {
  background-color: colors.$PRIMARY;
}

.header-content {
  display: flex;
  gap: 26px;

  color: white;
  padding: 16px;
  font-weight: 600;
  font-size: 18px;
  margin-left: 14px;
}

.header-item {
  cursor: pointer;
}

.header-fixed {
  top: 0;
  width: 100%;
  position: fixed;
}