@use '../../style/_shared.scss';
@use '../../style/_colors.scss';

$MORE_BOTTOM_MARGIN:16px;

.projects-container {
  @include shared.pageSection();

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: colors.$SECONDARY;
}

.projects-content {
  display: flex;
  flex-direction: column;
  gap: shared.$ITEM_HEIGHT_GAP - $MORE_BOTTOM_MARGIN;

  @include shared.mobile() {
    gap: 50px;
  }
}

.project-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include shared.mobile() {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }
}

.project-item-content {
  display: flex;
  align-items: center;
  gap: shared.$ITEM_WIDTH_GAP;

  @include shared.mobile() {
    flex-direction: column;
  }
}

.project-info {
  width: shared.$ITEM_LEFT_WIDTH;

  @include shared.mobile() {
    width: shared.$ITEM_LEFT_WIDTH_MOBILE;
  }
}

.project-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: shared.$ITEM_RIGHT_WIDTH;

  @include shared.mobile() {
    width: shared.$ITEM_RIGHT_WIDTH_MOBILE;
  }

  .project-release-date {
    color: colors.$SECONDARY_TEXT;
    font-size: 16px;
  }

  .img {
    width: 192px;
    height: 192px;
  }
}

.project-header {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  .project-platforms {
    display: flex;
    gap: 20px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.project-description {
  line-height: shared.$PARAGRAPH_LINE_HEIGHT;
}

.more-button {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: $MORE_BOTTOM_MARGIN;
  font-size: 18px;
  cursor: pointer;
  color: colors.$PRIMARY;
  font-weight: 500;
  border-bottom: 1px solid colors.$PRIMARY;

  @include shared.mobile() {
    margin-top: 20px;
  }

  div {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  img {
    width: 18px;
    height: 18px;
  }
}

.more-container {
  height: 0;
  overflow: hidden;
  background-color: white;
  border-radius: 12px;
  transition: height 0.2s ease-out;
}

.more-content{
  display: flex;
  width: 100%;
  padding: 0px 22px;

  @include shared.mobile() {
    padding: 0px;
  }

  .more-description {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 20px;

    @include shared.mobile() {
      padding: 0px;
    }

    .text {
      width: 400px;
      text-align: center;
      line-height: shared.$PARAGRAPH_LINE_HEIGHT;

      @include shared.mobile() {
        font-size: 12px;
        width: 200px;
        line-height: normal;
      }
    }
  }
}

.more-image-viewer {
  display: flex;
  align-items: center;
  gap: 18px;

  .more-images {
    img{
      border: 1px solid colors.$PRIMARY_TEXT;
    }
  }

  .more-nav-btn {
    cursor: pointer;
    align-self: center;

    &.inactive {
      opacity: 0.2;
      cursor: default;
    }

    img {
      width: 32px;
      height: 32px;
    }
  }
}