@use './_colors.scss';

$ITEM_LEFT_WIDTH:200px;
$ITEM_RIGHT_WIDTH:600px;

$ITEM_LEFT_WIDTH_MOBILE:250px;
$ITEM_RIGHT_WIDTH_MOBILE:250px;

$ITEM_WIDTH_GAP:40px;
$ITEM_HEIGHT_GAP:55px;
$ITEM_HEIGHT_GAP_MOBILE:80px;

$PARAGRAPH_LINE_HEIGHT:1.64;
$LOCATION_ICON_HEIGHT:18px;

$mobile-max-width: 900px;

@mixin mobile {
  @media screen and (max-width: #{$mobile-max-width}) {
    @content;
  }
}

.section-header {
  text-align: center;
  font-size: 45px;
  margin-bottom: 20px;
  font-weight: 300;
  color: colors.$PRIMARY;
  width: $ITEM_LEFT_WIDTH + $ITEM_RIGHT_WIDTH + $ITEM_WIDTH_GAP;

  @include mobile() {
    width: $ITEM_LEFT_WIDTH_MOBILE + $ITEM_RIGHT_WIDTH_MOBILE + $ITEM_WIDTH_GAP;
  }

  hr {
    border:0;
    border-top: 1px solid colors.$SECONDARY_TEXT;
    opacity: 0.2; 
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
}

@mixin pageSection() {
  padding: 50px 0px;
}