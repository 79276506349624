@use '../../style/_shared.scss';
@use '../../style/_colors.scss';

.wh-container {
  @include shared.pageSection();

  display: flex;
  flex-direction: column;
  align-items: center;
}

.wh-content {
  display: flex;
  flex-direction: column;
  gap: shared.$ITEM_HEIGHT_GAP;

  @include shared.mobile() {
    gap: shared.$ITEM_HEIGHT_GAP_MOBILE;
  }
}

.work-item {
  display: flex;
  gap: shared.$ITEM_WIDTH_GAP;
  align-items: center;

  @include shared.mobile() {
    flex-direction: column;
  }

  .company-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: shared.$ITEM_LEFT_WIDTH;

    @include shared.mobile() {
      width: shared.$ITEM_LEFT_WIDTH_MOBILE;
    }
  }

  .company-logo {
    width: 60px;
    height: 60px;
  }

  .work-details {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: shared.$ITEM_RIGHT_WIDTH;

    @include shared.mobile() {
      width: shared.$ITEM_RIGHT_WIDTH_MOBILE;
    }

    .work-title {
      font-style: italic;
    }
  }

  .work-description {
    line-height: shared.$PARAGRAPH_LINE_HEIGHT;
  }

  .work-location {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    img {
      display: flex;
      justify-items: center;
      align-items: center;
      width: shared.$LOCATION_ICON_HEIGHT;
      height: shared.$LOCATION_ICON_HEIGHT;
    }
  }

  .skills {
    display: flex;
    gap: 10px;
  }
}

.skill {
  padding: 4px 16px;
  border-radius: 11px;
  font-size: 14px;
  font-weight: 400;
  color: white;
  background-color: colors.$PRIMARY;
}