@use './style/_colors.scss';

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 200;
  color: colors.$PRIMARY_TEXT;
}

div {
   //border: solid 1px black;
}

* {-webkit-font-smoothing: antialiased;}