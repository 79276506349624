@use '../../style/_shared.scss';
@use '../../style/_colors.scss';

.aboutme-container {
  @include shared.pageSection();

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 60px;
}

.aboutme-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;

  @include shared.mobile() {
    flex-direction: column;
  }
}

.aboutme-portrait {
  img {
    width: 250px;
    height: 250px;
  }
}

.aboutme-info {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .heading {
    font-weight: 600;
    margin-bottom: 4px;
  }
}

.aboutme-social {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  width: 280px;

  .social-icon {
    cursor: pointer;

    img {
      display: flex;
      justify-items: center;
      align-items: center;
      width: 40px;
      height: 40px;
    }
  }
}