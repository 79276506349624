.App {
  display: flex;
  flex-direction: column;
}

.section-parent-anchor {
  position: relative;
}

.section-anchor {
  position: absolute; 
  top: -80px; 
  left: 0;
}