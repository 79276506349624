@use '../../style/_shared.scss';
@use '../../style/_colors.scss';

.education-container {
  @include shared.pageSection();
  
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: colors.$SECONDARY;
}

.education-content {
  display: flex;
  flex-direction: column;
  gap: shared.$ITEM_HEIGHT_GAP;
}

.education-item {
  display: flex;
  justify-content: center;
  text-align: center;
}

.education-details {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .company-logo {
    width: 60px;
    height: 60px;
  }

  .education-location {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    img {
      display: flex;
      justify-items: center;
      align-items: center;
      width: shared.$LOCATION_ICON_HEIGHT;
      height: shared.$LOCATION_ICON_HEIGHT;
    }
  }

}